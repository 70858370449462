import { layout } from '../../shared/theme';

export default props => `
  display: flex;
  flex-direction: column;
  background-color: ${props.background};
  padding: 2rem;
  border-radius: 5px;
  color: white;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.15);
  margin-bottom: 1rem;
  &:last-of-type {
    margin-bottom: 0;
  }
  ${layout.bp.large} {
    margin-bottom: 0;
    margin-right: 1.5rem;
    width: 50%;
    &:last-of-type {
      margin-right: 0;
    }
  }
  h2 {
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 1.2;
    margin-top: 0;
  }
  p {
    font-size: 0.9rem;
    opacity: 0.75;
  }
`;
