import React from 'react'
import { css } from 'emotion';
import { layout } from '../../shared/theme'

const HeroIntro = ({ heading, body, children }) => (
  <div
    css={css`
      width: 100%;
      ${layout.bp.medium} {
        width: 45%;
      }
    `}
  >
    <h1
      css={css`
        margin-top: 0;
        font-size: 35px;
      `}
    >
      {heading}
    </h1>
    <p>{body}</p>
    {children}
  </div>
)

export default HeroIntro
