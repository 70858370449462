import React, { useState } from 'react';
import styled from 'react-emotion';
import { css } from 'emotion';
import { colors, layout } from '../shared/theme';

import Section from '../components/Section';
import SectionIntro from '../components/SectionIntro';
import { ContactForm, DemoForm } from '../components/ContactForm';
import Tabs from '../components/Tabs';
import Tab from '../components/Tab';
import useHashChange from '../hooks/useHashChange';

const FormSection = styled(Section)`
  padding: 3rem 0;
  ${layout.bp.medium} {
    padding: 5rem 0;
  }
`;

const GetInTouch = () => {
  const [currentTab, setCurrentTab] = useState(0);

  const handler = event => {
    if (event.target.matches('[data-tab-index]')) {
      const index = event.target.getAttribute('data-tab-index');
      setCurrentTab(parseInt(index));
    }
  };

  useHashChange(handler);

  return (
    <div
      id="contact"
      css={css`
        box-shadow: 0px 23px 45px 0px rgba(0, 0, 0, 0.1);
      `}
    >
      <Tabs currentTab={currentTab} setCurrentTab={setCurrentTab}>
        <Tab title="Get in touch" color={colors.primary} id="get-in-touch">
          <FormSection color={colors.primary}>
            <SectionIntro
              heading="Get in touch"
              body={
                <>
                  Have you got any questions about online brand guidelines?
                  We’re here to answer all of your questions.{' '}
                  <a href="mailto:info@brandcloudlines.com">
                    info@brandcloudlines.com
                  </a>
                </>
              }
              color={colors.primary}
            />
            <ContactForm color={colors.primary} />
          </FormSection>
        </Tab>

        <Tab title="Try the demo" color={colors.dark} id="try-the-demo">
          <FormSection color={colors.dark}>
            <SectionIntro
              heading="Try the demo"
              body={
                <>
                  Hundreds of business owners, brand managers, strategists and
                  designers are already using Cloudlines. The best way to see
                  how Cloudlines works is to try it out for yourself. Request
                  access below and you’ll have an invite in your inbox in no
                  time.
                </>
              }
              color={colors.dark}
            />
            <DemoForm color={colors.dark} />
          </FormSection>
        </Tab>
      </Tabs>
    </div>
  );
};

export default GetInTouch;
