import React from 'react';
import { withPrefix } from 'gatsby';
import { css } from 'emotion';
import styled from 'react-emotion';

const ClientCard = ({ className, image, name }) => (
  <div className={className}>
    {image && (
      <img
        css={css`
          user-drag: none;
          user-select: none;
          max-height: 100%;
          transform: scale3d(1, 1, 1);
        `}
        src={withPrefix(image)}
        alt={name}
      />
    )}
  </div>
);

export default styled(ClientCard)`
  width: 140px;
  height: 140px;
  border-radius: 5px;
  position: relative;
  background-color: ${props => props.backgroundColor || props.color};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2em 1.5em;
  transform: scale3d(1, 1, 1);
  transition: transform 0.2s;
  &:hover {
    transform: scale3d(1.1, 1.1, 1);
  }
`;
