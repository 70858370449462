import React from 'react';
import { css } from 'emotion';
import { colors } from '../../shared/theme';
import { getContrastColor } from '../../shared/helpers';

const checkbox = props => css`
  margin-top: 1.5em;
  margin-bottom: 1em;
  display: flex;

  input[type='checkbox'] {
    opacity: 0;
    height: 0;
    width: 0;
    margin: 0;
  }

  label {
    position: relative;
    display: flex;
    height: 40px;
    align-items: center;
    padding-left: 60px;
    cursor: pointer;
    text-align: left;
    font-size: 14px;
    line-height: 1.3;
    color: rgba(255, 255, 255, 0.5);
    user-select: none;
  }

  label::before,
  label::after {
    position: absolute;
    content: '';
    display: inline-block;
  }

  label::before {
    height: 40px;
    width: 40px;
    background: rgba(255, 255, 255, 0.25);
    border-radius: 5px;
    left: 0px;
    top: 0px;
  }

  label::after {
    height: 8px;
    width: 16px;
    border-left: 3px solid;
    border-bottom: 3px solid;
    transform: rotate(-45deg);
    left: 12px;
    top: 14px;
    color: ${props.labelColor ||
      (props.color && getContrastColor(props.color)) ||
      colors.light};
  }

  input[type='checkbox'] + label::after {
    content: none;
  }

  input[type='checkbox']:checked + label::before {
    background-color: ${props.color ? props.color : colors.blue};
  }

  input[type='checkbox']:checked + label::after {
    content: '';
  }

  input[type='checkbox']:focus + label::before {
    outline: rgba(0, 0, 0, 0.25) solid 2px;
  }
`;

const Checkbox = ({ label, labelColor, ...restProps }) => (
  <div css={checkbox(restProps)}>
    <input type="checkbox" id="checkbox" {...restProps} />
    <label htmlFor="checkbox">{label}</label>
  </div>
);

export default Checkbox;
