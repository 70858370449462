import React from 'react';
import { css, keyframes } from 'emotion';
import { colors } from '../../shared/theme';

const rotating = keyframes`
  from {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  to {
    transform: rotate3d(0, 0, 1, 360deg);
  }
`;

const RotatingText = ({ text, size = 100, ...props }) => {
  const chars = [...text.split(''), ' '];
  const angle = 360 / chars.length;
  return (
    <div
      css={css`
        width: ${size}px;
        height: ${size}px;
        position: relative;
        border-radius: 50%;
        margin: -${size / 2}px;
        user-select: none;
        transform-origin: center center;
        transform: translate(50%, 50%);
        animation: ${rotating} 5s linear infinite;
      `}
      className={props.className}
    >
      {chars.map((char, i) => (
        <span
          key={i}
          css={css`
            height: ${size / 2}px;
            position: absolute;
            width: 20px;
            text-align: center;
            left: 50%;
            top: 0;
            color: ${colors.blue};
            font-size: ${size / 8}px;
            font-weight: 600;
            transform-origin: bottom center;
            text-transform: uppercase;
            transform: translateX(-50%) rotate(${angle * i}deg);
          `}
        >
          {char}
        </span>
      ))}
    </div>
  );
};

export default RotatingText;
