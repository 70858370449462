import React from 'react';
import styled from 'react-emotion';
import { css } from 'emotion';
import { getContrastColor } from '../../shared/helpers';
import { colors, layout } from '../../shared/theme';

import Container from '../Container';

const CenteredContainer = styled(Container)`
  text-align: center;
  width: 100%;
  ${layout.bp.medium} {
    width: 75%;
  }
  ${layout.bp.large} {
    width: 50%;
  }
`;

const SectionIntro = ({ heading, body, color = colors.light }) => (
  <div
    css={css`
      background-color: ${color || colors.light};
      color: ${(color && getContrastColor(color)) || colors.dark};
    `}
  >
    <CenteredContainer>
      {heading && (
        <h2
          css={css`
            font-size: 28px;
            margin-top: 0;
            margin-bottom: 0.5em;
            ${layout.bp.medium} {
              font-size: 35px;
            }
          `}
        >
          {heading}
        </h2>
      )}
      {body && <p>{body}</p>}
    </CenteredContainer>
  </div>
);

export default SectionIntro;
