import React, { Component } from 'react';
import styled from 'react-emotion';
import { css } from 'emotion';

import { getContrastColor } from '../../shared/helpers';
import { colors, layout } from '../../shared/theme';

import Button from '../Button';
import Container from '../Container';
import Pattern from '../Pattern';
import Float from '../Float';
import Checkbox from '../Checkbox';

const SUBMIT_URL = 'https://usebasin.com/f/1181e1d0b19e';
const SUCCESS_MESSAGE = `Message sent! We'll get back to you soon.`;
const ERROR_MESSAGE = `We're sorry, something went wrong. Please try again later.`;
const CONSENT_LABEL = `I consent to Cloudlines storing my submitted information so they can respond to my enquiry.`;

const CenteredContainer = styled(Container)`
  text-align: center;
  width: 100%;
  ${layout.bp.medium} {
    max-width: 500px;
  }
`;

class Form extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      displayError: false,
      displaySuccess: false,
    };
  }

  _displaySuccess = (timeout = 3000) => {
    this.setState({ displaySuccess: true });
    setTimeout(() => this.setState({ displaySuccess: false }), timeout);
  };

  _displayError = (timeout = 3000) => {
    this.setState({ displayError: true });
    setTimeout(() => this.setState({ displayError: false }), timeout);
  };

  _postForm = data => {
    console.log(data);
    this.setState({
      isLoading: true,
      displaySuccess: false,
      displayError: false,
    });

    fetch(SUBMIT_URL, { method: 'POST', body: data })
      .then(() => {
        this.setState({ isLoading: false });
        this._displaySuccess();
      })
      .catch(() => {
        this.setState({ isLoading: false });
        this._displayError();
      });
  };

  _handleSumbit = e => {
    const data = new FormData(e.target);
    e.preventDefault();
    e.target.reset();
    this._postForm(data);
  };

  render() {
    const { color, children } = this.props;
    return (
      <div
        css={css`
          background-color: ${color || colors.light};
          color: ${(color && getContrastColor(color)) || colors.dark};
        `}
      >
        <CenteredContainer>
          <form
            onSubmit={this._handleSumbit}
            css={css`
              display: flex;
              flex-direction: column;
              margin-top: 2rem;
              position: relative;
              opacity: ${this.state.isLoading ? 0.5 : 1};
              input,
              textarea {
                padding: 15px 20px;
                margin-bottom: 10px;
                display: block;
                min-height: 60px;
                border: 0;
                border-radius: 5px;
                box-sizing: border-box;
                background: ${colors.light};
                color: ${colors.dark};
                font-weight: 300;
                resize: vertical;
              }
              textarea {
                height: 120px;
              }
            `}
          >
            {children}
          </form>
          {this.state.displaySuccess && <p>{SUCCESS_MESSAGE}</p>}
          {this.state.displayError && <p>{ERROR_MESSAGE}</p>}
        </CenteredContainer>
      </div>
    );
  }
}

export class ContactForm extends Component {
  constructor() {
    super();
    this.state = {
      hasAccepted: false,
    };
  }

  _handleChange = event => {
    this.setState({
      hasAccepted: event.target.checked,
    });
  };

  render() {
    return (
      <div>
        <Float
          style={`
            left: 60%;
            bottom: 10%;
          `}
        >
          <Pattern type="dots" color="rgba('255, 255, 255, 0.25')" />
        </Float>
        <Form color={this.props.color}>
          <input type="hidden" name="form" value="Contact" />
          <input name="name" type="text" placeholder="Full name" />
          <input
            name="email"
            id="email"
            type="email"
            placeholder="Email address*"
            required
          />
          <textarea
            name="notes"
            type="text"
            placeholder="How can we help you?"
          />
          <Checkbox
            label={CONSENT_LABEL}
            value={this.state.hasAccepted}
            onChange={this._handleChange}
            required
          />
          <Button
            disabled={!this.state.hasAccepted}
            color={colors.blue}
            labelColor={colors.light}
            solid
            type="submit"
          >
            Send message
          </Button>
        </Form>
      </div>
    );
  }
}

export class DemoForm extends Component {
  constructor() {
    super();
    this.state = {
      hasAccepted: false,
    };
  }

  _handleChange = event => {
    this.setState({
      hasAccepted: event.target.checked,
    });
  };

  render() {
    return (
      <div>
        <Float
          style={`
            left: 60%;
            bottom: 10%;
          `}
        >
          <Pattern type="lines" color={colors.pink} />
        </Float>
        <Form color={this.props.color}>
          <input type="hidden" name="form" value="Demo" />
          <input name="name" type="text" placeholder="Full name" />
          <input
            name="email"
            id="email"
            type="email"
            placeholder="Email address*"
            required
          />
          <input name="job-title" type="text" placeholder="Job title" />
          <Checkbox
            color={colors.green}
            labelColor={colors.dark}
            label={CONSENT_LABEL}
            value={this.state.hasAccepted}
            onChange={this._handleChange}
            required
          />
          <Button
            disabled={!this.state.hasAccepted}
            color={colors.green}
            labelColor={colors.dark}
            solid
            type="submit"
          >
            Request access
          </Button>
        </Form>
      </div>
    );
  }
}
