import styled from 'react-emotion';
import Button from '../Button';

const TabButton = styled(Button)`
  border-radius: 0px;
  border: none;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0.05em;
  width: 240px;
  max-width: 50%;
  &:focus {
    outline: none;
  }
`;

export default TabButton;
