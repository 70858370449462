import React from 'react';
import { graphql, Link } from 'gatsby';
import { css } from 'emotion';
import { Trail } from 'react-spring';

import Layout from '../components/Layout';
import ClientScroller from '../components/ClientScroller';
import HeroBanner from '../components/HeroBanner';
import Contact from '../components/Contact';
import Section from '../components/Section';
import Sweep from '../components/Sweep';
import Float from '../components/Float';
import Pattern from '../components/Pattern';
import Clients from '../components/Clients';
import Button from '../components/Button';
import PricingCard from '../components/PricingCard';
import CustomCard from '../components/CustomCard';
import Container from '../components/Container';

import { colors, animation, layout } from '../shared/theme';

const PricingPage = ({ data }) => {
  const { site, pricing } = data;
  return (
    <Layout>
      <HeroBanner {...pricing.hero} />
      <Sweep
        top={{
          default: 500,
          medium: 700,
        }}
      />
      <Section
        color={colors.mid}
        className={css`
          padding-top: 2rem;
          padding-bottom: 4rem;
          margin-top: 6rem;
          ${layout.bp.medium} {
            margin-top: 0;
          }
        `}
      >
        <Float
          style={`
              left: 0;
              top: 4rem;
              transform: translateY(-50%);
            `}
        >
          <Pattern type="lines" color={colors.pink} />
        </Float>
        <Container
          id="plans"
          css={css`
            display: flex;
            flex-direction: column;
            ${layout.bp.large} {
              flex-direction: row;
            }
          `}
        >
          <Trail
            items={pricing.plans}
            keys={card => card.heading}
            from={{
              opacity: 0,
              transform: 'translate3d(0, 50px, 0)',
            }}
            to={{
              opacity: 1,
              transform: 'translate3d(0, 0, 0)',
            }}
            config={animation.spring}
          >
            {(card, state, i) => props => (
              <PricingCard {...card} animationStyle={props} />
            )}
          </Trail>
        </Container>
        <Container>
          <p
            css={css`
              text-align: center;
              font-size: 0.9rem;
            `}
          >
            All subscription plans include{' '}
            <strong>
              unlimited viewers, daily cloud backups, email support
            </strong>{' '}
            <br /> and a <strong>free training session</strong> as standard.
          </p>
        </Container>
        <Container
          css={css`
            display: flex;
            flex-direction: column;
            margin-top: 4rem;
            ${layout.bp.large} {
              flex-direction: row;
            }
          `}
        >
          <CustomCard
            background={colors.blue}
            heading={
              <>
                Using Cloudlines with <br />
                multiple clients?
              </>
            }
            text="Enquire about our Agency Dashboard for the ability to manage multiple Cloudlines from one space with unlimited agency users and priority support."
            renderButton={
              <a
                href="#contact"
                css={css`
                  text-decoration: none;
                  width: 100%;
                `}
              >
                <Button
                  data-tab-index="0"
                  labelColor={colors.blue}
                  color={colors.light}
                  css={css`
                    width: 100%;
                  `}
                  solid
                >
                  Contact Us
                </Button>
              </a>
            }
          />
          <CustomCard
            background={colors.dark}
            heading={
              <>
                Need more storage, users or <br />
                bespoke features?
              </>
            }
            text="We can tailor your Cloudlines to fit your needs. Get in touch to discuss bespoke feature requirements and enhanced subscription plans."
            renderButton={
              <a
                href="#contact"
                css={css`
                  text-decoration: none;
                  width: 100%;
                `}
              >
                <Button
                  data-tab-index="0"
                  labelColor={colors.light}
                  color={colors.primary}
                  css={css`
                    width: 100%;
                  `}
                  solid
                >
                  Contact Us
                </Button>
              </a>
            }
          />
        </Container>
      </Section>

      <Section
        css={css`
          padding-top: 0rem;
          background-color: ${colors.mid};
        `}
      >
        <Clients
          css={css`
            margin-top: -4rem;
          `}
          backgroundColor={colors.mid}
        >
          <ClientScroller clients={site.clients} />
        </Clients>
      </Section>
      <Contact />
    </Layout>
  );
};

export const pricingPageQuery = graphql`
  {
    site: globalJson {
      clients {
        color
        image
        name
        backgroundColor
      }
    }
    pricing: pricingJson {
      hero {
        heading
        body
      }
      plans {
        heading
        intro
        features {
          text
        }
        yearly
        monthly
        featureFlash
      }
    }
  }
`;

export default PricingPage;
