import { useEffect } from 'react';

function useHashChange(callback) {
  useEffect(() => {
    document.addEventListener('click', callback, true);
    return () => document.addEventListener('click', callback, true);
  }, []);
}

export default useHashChange;
