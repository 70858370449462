import React from 'react';
import styled from 'react-emotion';
import { css } from 'emotion';
import ReactSVG from 'react-svg';
import styles from './styles';
import RotatingText from '../RotatingText';
import { layout } from '../../shared/theme';
import { isTrue } from '../../shared/helpers';

const PricingCard = ({
  heading,
  intro,
  features,
  yearly,
  monthly,
  ...props
}) => (
  <div style={props.animationStyle} className={props.className}>
    {isTrue(props.featureFlash) && (
      <RotatingText
        text={props.featureFlash}
        css={css`
          position: absolute;
          top: 60px;
          right: 60px;
          ${layout.bp.large} {
            top: 0;
            right: 0;
          }
        `}
      />
    )}
    <div>
      <h3>{heading}</h3>
      <p className="intro">{intro}</p>
      {features.length && (
        <ul>
          {features.map(x => (
            <li key={x.text}>
              {x.icon && <ReactSVG className="icon" src={x.icon} />}
              <p>{x.text}</p>
            </li>
          ))}
        </ul>
      )}
    </div>
    <div className="footer">
      <h4>£{yearly}</h4>
      <p>per month (billed annually)</p>
    </div>
  </div>
);

export default styled(PricingCard)(styles);
