import React from 'react';
import { css } from 'emotion';

const Float = ({ style, children }) => (
  <div
    css={css`
      position: absolute;
      ${style};
    `}
  >
    {children}
  </div>
);

export default Float;
