import React, { Component } from 'react';
import { css } from 'emotion';
import ClientCard from '../ClientCard';
import Malarquee from 'react-malarquee';

class ClientScroller extends Component {
  render() {
    return (
      <div
        css={css`
          max-width: 100%;
          position: relative;
          user-select: none;
          z-index: 1;
          display: flex;
          margin-bottom: -2rem;
        `}
      >
        <Malarquee rate={25} hoverToPause fill>
          <div
            css={css`
              margin-left: auto;
              margin-right: auto;
              margin-top: 1rem;
              margin-bottom: 3.5rem;
              display: flex;
            `}
          >
            {this.props.clients.map((clientProps, i) => (
              <ClientCard
                key={i}
                css={css`
                  margin: 0.5rem;
                  flex-shrink: 0;
                `}
                {...clientProps}
              />
            ))}
          </div>
        </Malarquee>
      </div>
    );
  }
}

export default ClientScroller;
