import styled from 'react-emotion';

const Section = styled('div')`
  padding: 8rem 0;
  background-color: ${props => props.color || 'transparent'};
  // overflow: hidden;
  position: relative;
`;

export default Section;
