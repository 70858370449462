import React from 'react';
import { colors, layout } from '../../shared/theme';
import { css } from 'emotion';

const Curve = props => (
  <div
    css={css`
      width: 100%;
      position: absolute;
      top: 0;
      overflow: hidden;
      transform: translateY(-100%);
      pointer-events: none;
      svg {
        margin-bottom: -10px;
        opacity: 0.025;
        fill: ${colors.primary};
      }
    `}
  >
    <svg
      css={css`
        width: 150%;
        transform: scale(${props.mirrored ? '-1, 1' : '1, 1'});
      `}
      viewBox="0 0 1904 342.8"
    >
      <path d="M0,342.8h1904V1.6C1759.3-2.5,1185.5-8.5,654.1,123.5C415.2,182.8,185.6,266.2,0,342.8z" />
    </svg>
  </div>
);

const Sweep = ({ top }) => (
  <div
    css={css`
      position: absolute;
      top: ${top.default || top}px;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100vh;
      transform: scale(-1, 1);
      ${layout.bp.medium} {
        top: ${top.medium || top.default || top}px;
      }
    `}
  >
    <Curve />
    <Curve mirrored />
    <div
      css={css`
        background-color: ${colors.mid};
        height: 100%;
      `}
    />
  </div>
);

export default Sweep;
