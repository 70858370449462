import React from 'react';
import { withPrefix } from 'gatsby';
import { css } from 'emotion';
import Container from '../Container';
import HeroIntro from '../HeroIntro';
import { colors, layout } from '../../shared/theme';

const HeroBanner = ({ heading, body, image, imageAlt, ...props }) => (
  <Container
    css={css`
      position: relative;
      padding: 0 1rem;
      padding-top: 8rem;
      ${layout.bp.medium} {
        padding: 4rem 1rem;
        padding-top: 14rem;
      }
    `}
  >
    <HeroIntro {...{ heading, body }}>
      {props.button && <div>{props.button}</div>}
    </HeroIntro>

    {image && (
      <div
        css={css`
          position: relative;
          width: 100%;
          left: 0;
          margin: 0;
          padding-top: 50px;
          ${layout.bp.medium} {
            position: absolute;
            z-index: 1;
            padding: 0;
            padding-top: 10rem;
            left: 42.5%;
            width: 650px;
            margin-left: 100px;
            top: 50%;
            transform: translateY(-50%);
            bottom: auto;
          }
        `}
      >
        <img
          style={{ borderRadius: 26.5 }}
          alt={imageAlt}
          src={withPrefix(image)}
        />
      </div>
    )}
  </Container>
);

export default HeroBanner;
