import React from 'react';
import { css } from 'emotion';
import { colors } from '../../shared/theme';
import { hexToRgba } from '../../shared/helpers';

function encodeSVG(data) {
  const symbols = /[\r\n%#()<>?[\\\]^`{|}]/g;
  data = data.replace(/"/g, "'");
  data = data.replace(/>\s{1,}</g, '><');
  data = data.replace(/\s{2,}/g, ' ');
  return data.replace(symbols, encodeURIComponent);
}

const patterns = {
  lines: color => `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" width="60px" height="60px">
      <style type="text/css">
        .color {
          fill: none; 
          stroke: ${color};
          stroke-miterlimit: 10;
          stroke-width: 1.5;
        }
      </style>
      <g>
        <line class="color" x1="7.1" y1="12.7" x2="12.7" y2="7.1"/>
        <line class="color" x1="27.2" y1="12.7" x2="32.8" y2="7.1"/>
        <line class="color" x1="47.3" y1="12.7" x2="52.9" y2="7.1"/>
        <line class="color" x1="7.1" y1="32.8" x2="12.7" y2="27.2"/>
        <line class="color" x1="27.2" y1="32.8" x2="32.8" y2="27.2"/>
        <line class="color" x1="47.3" y1="32.8" x2="52.9" y2="27.2"/>
        <line class="color" x1="7.1" y1="52.9" x2="12.7" y2="47.3"/>
        <line class="color" x1="27.2" y1="52.9" x2="32.8" y2="47.3"/>
        <line class="color" x1="47.3" y1="52.9" x2="52.9" y2="47.3"/>
      </g>
    </svg>
  `,
  dots: color => `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" width="60px" height="60px">
      <style type="text/css">
        .color {
          fill: ${color};
        }
      </style>
      <g>
        <circle class="color" cx="9.9" cy="9.9" r="1.5" />
        <circle class="color" cx="30" cy="9.9" r="1.5" />
        <circle class="color" cx="50.1" cy="9.9" r="1.5" />
        <circle class="color" cx="9.9" cy="30" r="1.5" />
        <circle class="color" cx="30" cy="30" r="1.5" />
        <circle class="color" cx="50.1" cy="30" r="1.5" />
        <circle class="color" cx="9.9" cy="50.1" r="1.5" />
        <circle class="color" cx="30" cy="50.1" r="1.5" />
        <circle class="color" cx="50.1" cy="50.1" r="1.5" />
      </g>
    </svg>
  `,
};

const Pattern = ({ type, color = hexToRgba(colors.dark, 0.25) }) => (
  <div
    css={css`
      background-image: url("data:image/svg+xml, ${encodeSVG(
        type ? patterns[type](color) : patterns.dots(color)
      )}");
      background-repeat: repeat;
      width: 245px;
      height: 245px;
      pointer-events: none;
    `}
  />
);

export default Pattern;
