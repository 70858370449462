import { colors, layout } from '../../shared/theme';

export default `
  display: flex;
  padding: 4rem 2rem;
  background-color: ${colors.light};
  position: relative;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.15);
  margin-bottom: 1rem;
  width: 100%;
  &:last-of-type {
    margin-bottom: 0;
  }
  ${layout.bp.large} {
    margin-bottom: 0;
    margin-right: 1.5rem;
    &:last-of-type {
      margin-right: 0;
    }
  }
  h3 {
    color: ${colors.primary};
    font-size: 1.6rem;
    font-weight: 400;
    margin: 0;
  }
  .intro {
    opacity: 0.5;
    line-height: 1.4;
    font-size: 0.9rem;
    margin-bottom: 1.5rem;
  }
  ul {
    list-style: none;
    margin: 0;
    li {
      font-size: 0.9rem;
      margin: 0.25em 0;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      .icon {
        margin-right: 0.75em;
        width: 20px;
        fill: ${colors.primary};
      }
    }
  }
  .footer {
    margin-top: 1.5rem;
    h4 {
      color: ${colors.dark};
      font-size: 3.5rem;
      line-height: 1;
      font-weight: 300;
      margin: 0;
    }
    p {
      color: ${colors.dark};
      opacity: 0.5;
      font-size: 0.75rem;
      margin: 0;
    }
  }
`;
