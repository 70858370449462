import React from 'react';
import styled from 'react-emotion';
import styles from './styles';

const CustomCard = ({ className, ...props }) => (
  <div className={className}>
    <h2>{props.heading}</h2>
    <p>{props.text}</p>
    {props.renderButton}
  </div>
);

export default styled(CustomCard)(styles);
