import React from 'react';
import { css } from 'emotion';
import { Transition, animated } from 'react-spring';
import TabButton from '../TabButton';

const Tabs = ({ children, setCurrentTab, currentTab = 0 }) => {
  const tabs = children.length ? children : [children];
  return (
    <div
      css={css`
        position: relative;
      `}
    >
      <div
        css={css`
          position: absolute;
          bottom: 100%;
          left: 50%;
          transform: translateX(-50%);
          width: 100%;
          display: flex;
          justify-content: center;
        `}
      >
        {tabs.map((x, i) => (
          <TabButton
            key={i}
            solid
            color={x.props.color}
            onClick={() => setCurrentTab(i)}
          >
            {x.props.title}
          </TabButton>
        ))}
      </div>
      <Transition
        items={currentTab}
        from={{ opacity: 1, zIndex: 1 }}
        enter={{ opacity: 1 }}
        leave={{ opacity: 0, position: 'absolute', width: '100%', zIndex: 0 }}
        config={{
          duration: 300,
        }}
      >
        {toggle => props => (
          <animated.div style={props}>{tabs[toggle]}</animated.div>
        )}
      </Transition>
    </div>
  );
};

export default Tabs;
