import React from 'react';
import Container from './Container';
import { css } from 'emotion';

const Clients = ({ data, ...props }) => (
  <>
    <Container
      css={css`
        display: flex;
        position: relative;
        z-index: 1;
        flex-direction: column;
        align-items: center;
        text-align: center;
      `}
    >
      <h2
        css={css`
          font-size: 24px;
        `}
      >
        Forward-thinking companies already using Brand Cloudlines
      </h2>
    </Container>
    {props.children}
  </>
);

export default Clients;
